import React from "react";
import ProviderInfo from "./ProviderInfo";
import LocationInfo from "./LocationInfo";

const FacilityCard = ({
  provider: {
    id,
    NPI,
    Last,
    First,
    ProviderType,
    IMS_Code,
    IMS_Description,
    Professional_Designation,
    Taxonomy_1,
    Taxonomy_2,
    AHSProviderLocations,
  }, searchCity, searchCounty,
}) => {
const displayLocation = (location) => {
  if (
    (searchCity === "" && searchCounty === "") || 
    (searchCity !== "" && location.AHS_PhysicalLocation.City.toLowerCase().startsWith(searchCity.toLowerCase())) ||
    (searchCounty !== "" && location.AHS_PhysicalLocation.County?.toLowerCase().startsWith(searchCounty.toLowerCase()))
  ) {
    return (
    <div className="provider-card">
          <ProviderInfo
            {...{
              id,
              NPI,
              First,
              Last,
              ProviderType,
              IMS_Code,
              IMS_Description,
              AHSProviderLocations,
            }}
          />
          <div className="location">
            <LocationInfo location={location} providerType={ProviderType} searchCity={searchCity} searchCounty={searchCounty} />
          </div>
        </div>
    );
  }
}
  return (
    <div className="provider-info">
      {AHSProviderLocations.map((location) => (
        displayLocation(location)
      ))}
    </div>
  );
};

export default FacilityCard;
