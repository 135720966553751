import React from "react";
import { PatternFormat } from "react-number-format";

const LocationInfo = ({ location, providerType, specialty, searchCity, searchCounty }) => {
const displayLocation = () => {
  if (
      providerType === 2 || 
      (searchCity === "" && searchCounty === "") || 
      (searchCity !== "" && location.AHS_PhysicalLocation.City.toLowerCase().startsWith(searchCity.toLowerCase())) || 
      (searchCounty !== "" && location.AHS_PhysicalLocation.County?.toLowerCase().startsWith(searchCounty.toLowerCase()))
    ) {
    return (
    <div className="location-container">
      <div className="location-info">
      <span>
        {location.AHS_PhysicalLocation.Address_1},{" "}
        {location.AHS_PhysicalLocation.City},{" "}
        {location.AHS_PhysicalLocation.State}{" "}
        {location.AHS_PhysicalLocation.Zip}
      </span>
      <br />
      {displayCounty()}
      <span>
        Phone:&nbsp;
        <PatternFormat
          displayType="text"
          value={location.AHS_PhysicalLocation.Phone}
          format="(###) ###-####"
        />
      </span>
      <br />
      <span>
        Fax:&nbsp;
        <PatternFormat
          displayType="text"
          value={location.AHS_PhysicalLocation.FAX}
          format="(###) ###-####"
        />
      </span>
      </div>
      <div className="extended-location-info">
        {providerOnlyExtraInfo()}
        <p><span className="lable">Wheelchair Accessible:</span> Unknown</p>
        <p><span className="lable">Accepts New Patients:</span> Unknown</p>
     </div>
   </div>
    );
  }
}

  const providerOnlyExtraInfo = () => {
    if (providerType === 1) {
      return (
        <>
        <p><span className="lable">Languages Spoken:</span> Unknown</p>
        <p><span className="lable">Specialty:</span> {specialty}</p>
        </>
      );
    }
  }

  const displayCounty = () => {
    if (location.AHS_PhysicalLocation.County !== null) {
      return (
        <>
        <span>{location.AHS_PhysicalLocation.County} County</span>
        <br />
        </>
      );
    }
  }

  return (
    displayLocation()
  );
};





export default LocationInfo;
