import React, { useRef } from "react";
import { useEffect, useState } from "react";
import ProviderCard from "./ProviderCard";
import FacilityCard from "./FacilityCard";
import "./App.css";
import SearchIcon from "./search.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSliders } from '@fortawesome/free-solid-svg-icons';


const API_URL = "/AHS_Providers_static.json";

const App = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedInputValue, setDebouncedInputValue] = useState("");
  const [city, setCity] = useState("");
  const [debouncedCity, setDebouncedCity] = useState("");
  const [county, setCounty] = useState("");
  const [debouncedCounty, setDebouncedCounty] = useState("");
  const timeoutValue = 1000;
  const [searchType, setSearchType] = useState("Provider by Name");
  const [searchProviderType, setSearchProviderType] = useState(1);
  const [providers, setProviders] = useState([]);
  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  }

  const handleCountyChange = (e) => {
    setCounty(e.target.value);
  }
  
  const handleButtonClick = (searchType) => {
    setSearchType(searchType);
    setSearchProviderType(searchType.startsWith("Provider") ? 1 : 2);
    inputRef.current.focus();
  };

  const searchProviders = async (ProviderType) => {
    const response = await fetch(`${API_URL}`);
    const data = await response.json();

    setProviders(data);
  };

  const filterProviders = (item) => {
    if (searchType === "" || debouncedInputValue === "" || item.ProviderType !== searchProviderType) {
      return null;
    }

    let match = true;
    if (searchType.endsWith("Name")) {
      match = match && nameSearch(item);
    }
    else if (searchType.endsWith("Specialty")) {
      match = match && specialtySearch(item);
    }

    if (debouncedCity) {
      match = match && citySearch(item);
    }

    if (debouncedCounty) {
      match = match && countySearch(item);
    }

    return match;
  }

  const nameSearch = (item) => {
    if (searchProviderType === 1) {
      return item.Last?.toLowerCase().startsWith(debouncedInputValue.toLowerCase()) || item.First?.toLowerCase().startsWith(debouncedInputValue.toLowerCase());
    }
    return item.Last?.toLowerCase().includes(debouncedInputValue.toLowerCase());
  }

  const specialtySearch = (item) => {
    return item.IMS_Description?.toLowerCase().includes(debouncedInputValue.toLowerCase());
  }

  const citySearch = (item) => {
    const cityMatches = item.AHSProviderLocations.filter((location) => location.AHS_PhysicalLocation.City.toLowerCase().startsWith(debouncedCity.toLowerCase()));
    return cityMatches.length;
  }

  const countySearch = (item) => {
    const countyMatches = item.AHSProviderLocations.filter((location) => location.AHS_PhysicalLocation.County?.toLowerCase().startsWith(debouncedCounty.toLowerCase()));
    return countyMatches.length;
  }

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(searchTerm);
      setDebouncedCity(city);
      setDebouncedCounty(county);
    }, timeoutValue);

    return () => {
      clearTimeout(delayInputTimeoutId);
    };
  }, [searchTerm, timeoutValue, city, county]);

  useEffect(() => {
    searchProviders();
  }, []);


  return (
    <div className="app">
      <section>
        <div className="section-title">
          <div>
            <h2>Provider Search</h2>
            <h3>What are you searching for today?</h3>
          </div>
        </div>
        <div className="flex-container">
          <div 
            className={searchType === "Provider by Name" ? 'active' : ''}
            onClick={() => handleButtonClick("Provider by Name")}>
            <div className="search-selection">
              <img
                className="search-icons"
                src="./images/doctor.png"
                alt="icon"
              />
              <p>Provider by Name</p>
            </div>
          </div>
          <div 
            className={searchType === "Provider by Specialty" ? 'active' : ''}
            onClick={() => handleButtonClick("Provider by Specialty")}>
            <div className="search-selection">
              <img
                className="search-icons"
                src="./images/heart-with-pulse.png"
                alt="icon"
              />
              <p>Provider by Specialty</p>
            </div>
          </div>
          <div 
            className={searchType === "Facility by Name" ? 'active' : ''}
            onClick={() => handleButtonClick("Facility by Name")}>
            <div className="search-selection">
              <img
                className="search-icons"
                src="./images/building.png"
                alt="icon"
              />
              <p>Facility by Name</p>
            </div>
          </div>
          <div 
            className={searchType === "Facility by Specialty" ? 'active' : ''}
            onClick={() => handleButtonClick("Facility by Specialty")}>
            <div className="search-selection">
              <img
                className="search-icons"
                src="./images/stethascope.png"
                alt="icon"
              />
              <p>Facility by Specialty</p>
            </div>
          </div>
        </div>
      </section>

      <div className="search">
        <input
          ref={inputRef}
          value={searchTerm}
          onChange={handleInputChange}
          placeholder={"Search for " + searchType}
        />
        <img
          src={SearchIcon}
          alt="search"
        />
      </div>

      <div className="extend-filters">
        <div className="extend-filters-search">
          <input
            type="text"
            id="county"
            name="county"
            value={county}
            onChange={handleCountyChange}
            placeholder={"Filter by County"}
          />{" "}
          <FontAwesomeIcon icon={faSliders} />
        </div>
        <div className="extend-filters-search">
          <input
            type="text"
            id="city"
            name="city"
            value={city}
            onChange={handleCityChange}
            placeholder={"Filter by City"}
          />{" "}
          <FontAwesomeIcon icon={faSliders} />
        </div>
      </div>

      {providers?.length > 0 ? (
        <div className="container">
          {providers
            .filter(filterProviders)
            .map((provider) => (
              <div className="info" key={provider.ID}>
                {provider.ProviderType === 1 ? (
                  <ProviderCard provider={provider} searchCity={debouncedCity} searchCounty={debouncedCounty} />
                ) : (
                  <FacilityCard provider={provider} searchCity={debouncedCity} searchCounty={debouncedCounty} />
                )}
              </div>
            ))}
        </div>
      ) : (
        <div className="empty">
          <h2>No providers found</h2>
        </div>
      )}
    </div>
  );
};
export default App;
